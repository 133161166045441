import React  from 'react';
import { SERVICES } from './data.js';
import Hero from './components/Hero/Hero.jsx';
import Service from './components/Servise/Service.jsx';

function App() {
  return (
    <div>
      <Hero />
      <main>
        <section id="services">
          <h2>Quick Access Roadside Assistance Services</h2>
          <ul>
            {SERVICES.map((service) => (
              <Service key={service.title} {...service} />
            ))}
          </ul>
        </section>
      </main>
    </div>
  );
}

export default App;
