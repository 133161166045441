import React  from 'react';
import heroImg from '../../assets/hero.png';
import './Hero.css';

export default function Hero() {
  return (
    <header>
      <img src={heroImg} alt="Road hero" />
      <p>Need Help on the Road?</p>
      <h1>GET HELP NOW</h1>
    </header>
  );
}