import towingImg from './assets/towing.png';
import tireImg from './assets/tire-change.png';
import fuelImg from './assets/fuel-delivery.png';
import batteryImg from './assets/battery-jumpstart.png';

export const SERVICES = [
  {
    image: towingImg,
    title: 'Towing',
    description:
      'Fast and reliable towing service.',
  },
  {
    image: tireImg,
    title: 'Tire Change',
    description:
      'Quick tire change assistance.',
  },
  {
    image: fuelImg,
    title: 'Fuel Delivery',
    description:
      'We bring fuel to you when you need it.',
  },
  {
    image: batteryImg,
    title: 'Battery jumpstart',
    description:
      'We start your car less then a minute',
  },
];